var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-3 col-sm-5 mb-2 mb-lg-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Country..",
                          value: _vm.$store.state.filters.country,
                          options: _vm.authCountries,
                          reduce: (c) => c.value,
                          searchable: false,
                        },
                        on: { input: _vm.countryFilter },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md mb-1 mb-md-0 pl-md-0" },
                    [
                      _c("CInput", {
                        staticClass: "mb-0 search",
                        attrs: {
                          type: "search",
                          placeholder: "Search for something else ...",
                        },
                        on: { input: _vm.searchFilter },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No hub found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              content: "Edit",
                              placement: "top",
                            },
                            expression:
                              "{\n            content: 'Edit',\n            placement: 'top',\n          }",
                          },
                        ],
                        attrs: {
                          to: { name: "Edit Hub", params: { id: item.id } },
                          size: "sm",
                          color: "info",
                          variant: "ghost",
                          shape: "pill",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-align-left" } })],
                      1
                    ),
                    item.is_deletable === true
                      ? [
                          item.deleting
                            ? _c("CSpinner", {
                                staticClass: "ml-3",
                                attrs: { size: "sm", color: "danger" },
                              })
                            : _c(
                                "CButton",
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    size: "sm",
                                    color: "danger",
                                    variant: "ghost",
                                    shape: "pill",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item, index)
                                    },
                                  },
                                },
                                [_c("CIcon", { attrs: { name: "cil-trash" } })],
                                1
                              ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "id",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" " + _vm._s(item.id) + " ")])]
            },
          },
          {
            key: "zip",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "align-middle" },
                  [
                    _c("CIcon", {
                      staticClass: "mr-1",
                      attrs: {
                        content: _vm.$options.flagSet[item.country.flag],
                        size: "lg",
                      },
                    }),
                    _vm._v(" " + _vm._s(item.zip) + " "),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "name",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c("strong", [_vm._v(_vm._s(item.name.toUpperCase()))]),
                ]),
              ]
            },
          },
          {
            key: "scopes_count",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "text-center" }, [
                  _c("b", [_vm._v(_vm._s(item.scopes_count))]),
                ]),
              ]
            },
          },
          {
            key: "couriers_count",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "text-center" }, [
                  _c("b", [_vm._v(_vm._s(item.couriers_count))]),
                ]),
              ]
            },
          },
          {
            key: "created_at",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _vm._v(_vm._s(_vm.moment(item.created_at).format("LL"))),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }